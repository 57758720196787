import { useCallback, useState } from 'react';

interface Recording {
  title: string;
  data: string; 
}

const useRecentRecordings = () => {
  const [recordings, setRecordings] = useState<Recording[]>([]);

  const saveRecording = useCallback(async (chunks: Blob[]) => {
    if (chunks.length === 0) return;

    const recordingsKey = 'recentRecordings';
    const existingRecordings = JSON.parse(localStorage.getItem(recordingsKey) || '[]') as Recording[];

    const previousSession = JSON.parse(localStorage.getItem('PREVIOUS_SESSION') || '{}');
    const recordingTitle = previousSession?.name || new Date().toISOString();

    const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });

    const blobToBase64 = (blob: Blob): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    const data = await blobToBase64(blob);

    const newRecording: Recording = {
      title: recordingTitle,
      data,
    };

    const updatedRecordings = [newRecording, ...existingRecordings];

    if (updatedRecordings.length > 3) {
      updatedRecordings.pop(); 
    }

    localStorage.setItem(recordingsKey, JSON.stringify(updatedRecordings));
    setRecordings(updatedRecordings);
  }, []);

  const loadRecordings = useCallback(() => {
    const recordingsKey = 'recentRecordings';
    const savedRecordings = JSON.parse(localStorage.getItem(recordingsKey) || '[]') as Recording[];
    setRecordings(savedRecordings);
  }, []);

  return { recordings, saveRecording, loadRecordings };
};

export default useRecentRecordings;
