import { FC, useEffect, useRef, useState, lazy, startTransition } from 'react';
import { Mic, Settings, Pause, Trash, Play, Save, Archive } from 'lucide-react';
import RecordedFiles from './../components/RecordedFiles';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../components/ui/collapsible';
import { useAuth0 } from '@auth0/auth0-react';
import UserNav from '@/components/UserNav';
import { uploadFileToS3 } from '@/services/s3upload';
// import { formatTime } from '../lib/utils';
import { useSessionService } from '@/hooks/useSession';

import { addAudioChunk, getAudioChunks, deleteDatabase, clearAllChunks } from '@/utils/db';

import { DATABASE_NAME, PREVIOUS_SESSION } from '@/contants';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { Reason, ReasonType } from '@/types';
import { useCheckAccess } from '@/hooks/useCheckAccess';
import { Toast } from '@/components/ui/toast';
import LocalUpload from '@/components/LocalUpload';
import useRecentRecordings from '@/hooks/useRecentRecordings';

const DeleteModal = lazy(() => import('@/components/modals/DeleteModal'));
const DeleteConfirmationModal = lazy(() => import('@/components/modals/DeleteConfirmationModal'));
const SaveConfirmationModal = lazy(() => import('@/components/modals/SaveConfirmationModal'));
const ContinueRecordingModal = lazy(() => import('@/components/modals/ContinueRecordingModal'));
const SettingsModal = lazy(() => import('@/components/modals/SettingsModal'));
const SaveModal = lazy(() => import('@/components/modals/SaveModal'));
const RecordingsModal = lazy(() => import('@/components/modals/RecordingsModal'));

const MainComponent: FC = () => {
  const [file, setFile] = useState<Blob | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [numberOfChunks, setNumberOfChunks] = useState<number>(1);
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [chunks, setChunks] = useState<Blob[]>([]);
  const { user } = useAuth0();
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [paused, setPaused] = useState(false);
  const [retrySave, setRetrySave] = useState(false);

  const { startSession, endSession, deleteSession, session, setSession, isRecording, toast, setToast, setIsRecording, setRecordingError, showToast } = useSessionService();
  const [isDebugInfoOpen, setIsDebugInfoOpen] = useState(false);
  const [hasExistingRecording, setHasExistingRecording] = useState<boolean>(false);
  // const [recordingTime, setRecordingTime] = useState<number>(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const CODEC = 'audio/ogg; codecs=opus';
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const selectedDeviceId = localStorage.getItem('selectedDeviceId') || '';
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [saveConfirmationModalOpen, setSaveConfirmationModalOpen] = useState(false);
  const [continueRecordingModalOpen, setContinueRecordingModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [recordingsModalOpen, setRecordingsModalOpen] = useState(false);

  const [language, setLanguage] = useState('auto');
  const [participants, setParticipants] = useState('auto');
  const [meetingName, setMeetingName] = useState('');

  const [hasPermission, setHasPermission] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>();
  const disableRecording = useCheckAccess();

  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [micAccessBlocked, setMicAccessBlocked] = useState(false);
  const { saveRecording } = useRecentRecordings(); 
  const debugInfoVisible = import.meta.env.VITE_DEBUG_INFO === 'true';

  useEffect(() => {
    const detectMobileDevice = () => {
      const userAgent = navigator.userAgent;
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    };

    setIsMobile(detectMobileDevice());

    const handleResize = () => {
      if (isMobile) {
        startTransition(() => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    const getMediaDevices = async () => {
      try {
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = allDevices.filter((device) => device.kind === 'audioinput');
        setDevices(audioDevices);
        if (audioDevices.length > 0 && !selectedDeviceId) {
          localStorage.setItem('selectedDeviceId', audioDevices[0].deviceId);
        }
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };

    getMediaDevices();
  }, [hasPermission]);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: selectedDeviceId } })
        .then(async (stream) => {
          const mediaRec = new MediaRecorder(stream, {
            audioBitsPerSecond: 32000,
          });
          mediaRecorderRef.current = mediaRec;
          setMediaRecorder(mediaRec);

          setHasPermission(true);

          mediaRecorderRef.current.ondataavailable = async (e: BlobEvent) => {
            if (e.data.size > 0) {
              setChunks((prevChunks) => {
                const newChunks = [...prevChunks, e.data];
                setCurrentTime(new Date());
                setNumberOfChunks(newChunks.length);
                return newChunks;
              });

              await addAudioChunk(e.data);
            }
          };

          const savedChunks = await getAudioChunks();
          if (savedChunks.length > 0) {
            setChunks([...savedChunks.map((c) => c.chunk)]);
            setNumberOfChunks(savedChunks.length);
            setPaused(true);
          }
          setMicAccessBlocked(false);
        })
        .catch((err) => {
          setMicAccessBlocked(true);
          showToast(
            "Error",
            "Your browser's microphone access is blocked. Please enable it to start recording."
          );
          console.error(`The following getUserMedia error occurred: ${err}`);
        });
    } else {
      console.log('getUserMedia not supported on your browser!');
    }
  }, []);

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  useEffect(() => {
    const checkExistingRecording = async () => {
      const chunks = await getAudioChunks();
      if (chunks.length > 0) {
        const lastSession = localStorage.getItem(PREVIOUS_SESSION);

        if (lastSession !== null) {
          setSession(JSON.parse(lastSession));
        }

        setHasExistingRecording(true);
        setPaused(false);
        //const savedTime = Math.floor(chunks.length);
        //setRecordingTime(savedTime);
        setContinueRecordingModalOpen(true);
      }
    };

    checkExistingRecording();
  }, []);

  const start = async () => {
    try {
      if (!mediaRecorderRef.current) {
        showToast(
          "Error",
          "Your browser's microphone access is blocked. Please enable it to start recording."
        );
        return;
      }
      console.log('Start session');

      setStartTime(new Date());

      startRecording();
    } catch (e) {
      console.error(e);
    }
  };

  const clearExistingTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const startTimer = (source?: string) => {
    if (source !== 'resume') {
      clearExistingTimer();
    }

    // timerRef.current = setInterval(() => {
    // setRecordingTime((prevTime) => prevTime + 1);
    //  }, 1000);
  };

  const startRecording = async () => {
    try {
      if (!mediaRecorderRef.current) {
        console.error('MediaRecorder is not initialized.');
        return;
      }

      if (!isRecording && !hasExistingRecording) {
        await clearAllChunks();
        setChunks([]);
      }

      if (localStorage.getItem(PREVIOUS_SESSION) === null) {
        console.log('No session found.');
        try {
          await startSession();
        } catch (error) {
          console.error('Error starting session:', error);
          return;
        }
      }

      try {
        setIsRecording(true);
        startTimer();
        mediaRecorderRef.current.start(1000);
      } catch (error) {
        console.error('Error starting media recorder:', error);
        setIsRecording(false);
        return;
      }

      setContinueRecordingModalOpen(false);
      setAudioUrl(null);
      setPaused(false);
    } catch (error) {
      console.error('Error in startRecording function:', error);
    }
  };

  const stopRecording = async (reason?: Reason): Promise<boolean> => {
    try {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }

      if (reason === ReasonType.DELETE || reason === ReasonType.CANCEL) {
        await deleteSession();
        setChunks([]);
      } else if (reason === ReasonType.SAVE) {
        saveRecording(chunks);
        await endSession(language, participants, meetingName);
      }

      setPaused(false);
      clearExistingTimer();
      // setChunks([]);
      deleteDatabase(DATABASE_NAME);
      //setRecordingTime(0);
      setRecordingError(false);
      return true;
    } catch (error) {
      console.error('Error during stopRecording:', error);
      setRecordingError(true);
      return false;
    }
  };

  const handleSave = async () => {
    setRetrySave(false);
    const recordingStoppedSuccessfully = await stopRecording(ReasonType.SAVE);

    if (!recordingStoppedSuccessfully) {
      setSaveModalOpen(false);
      setSaveConfirmationModalOpen(false);
      setRetrySave(true);
      return;
    }

    setSaveConfirmationModalOpen(true);
    setSaveModalOpen(false);

    const recordedFile = new Blob(chunks, { type: CODEC });
    setFile(recordedFile);
    localStorage.removeItem(PREVIOUS_SESSION);

    const url = URL.createObjectURL(recordedFile);
    setAudioUrl(url);

    if (session?.s3_url) {
      try {
        await uploadFileToS3(chunks, session.s3_url);
        attachRecord();
      } catch (e) {
        showToast('Error', 'Error uploading file to s3. Try to download recording and add it manually to Megu Chat.');
        setSaveConfirmationModalOpen(false);
        console.log(e);
      }
    }

    setLanguage('auto');
    setParticipants('auto');
    setMeetingName('');
  };

  const handleDelete = async () => {
    const recordingStoppedSuccessfully = await stopRecording(ReasonType.DELETE);

    if (!recordingStoppedSuccessfully) {
      setDeleteModalOpen(false);
      setDeleteConfirmationModalOpen(false);
      return;
    }

    setAudioUrl(null);
    localStorage.removeItem(PREVIOUS_SESSION);

    setDeleteConfirmationModalOpen(true);
    setDeleteModalOpen(false);
  };

  const handlePause = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      clearExistingTimer();
      setTimeout(async () => {
        try {
          if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.pause();
            setPaused(true);
          }
        } catch (error) {
          console.error('Failed to pause the recording:', error);
        }
      }, 1000);
    } else {
      console.log('MediaRecorder is not in a recording state.');
    }
  };

  const handleResume = () => {
    if (mediaRecorderRef.current && paused) {
      mediaRecorderRef.current.resume();
      setPaused(false);
      startTimer('resume');
    }
  };

  const handleSaveButton = () => {
    handlePause();
    setSaveModalOpen(true);
  };

  const handleDeleteButton = () => {
    handlePause();
    setDeleteModalOpen(true);
  };

  const attachRecord = async () => {
    const savedChunks = await getAudioChunks();
    const blob = new Blob(
      savedChunks.map((c) => c.chunk),
      { type: CODEC }
    );
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
  };

  return (
    <>
      {isMobile && !isPortrait ? (
        <div className="flex flex-col items-center justify-center h-screen gradient-bg">
          <div className="flex flex-col items-center justify-center border p-6 rounded-lg shadow-lg bg-white">
            <h1 className="font-semibold text-xl mb-4">Please rotate your device to see recording app.</h1>
            <p>If you start your record, it is still recording.</p>
          </div>
        </div>
      ) : (
        <main>
          <div className="flex flex-col h-screen gradient-bg text-white">
            <header className="bg-gray-800 px-4 py-2 flex items-center justify-between">
              <div className="flex items-center">
                <h1 className="text-xl font-bold">Megu Recorder</h1>
              </div>
              <div className="flex items-center gap-2">
              <Button size="icon" variant="ghost" onClick={() => setRecordingsModalOpen(true)} disabled={devices.length === 0 || micAccessBlocked}>
                  <Archive className="h-6 w-6" />
                </Button>
                <Button size="icon" variant="ghost" onClick={() => setSettingsModalOpen(true)} disabled={devices.length === 0 || micAccessBlocked}>
                  <Settings className="h-6 w-6" />
                </Button>
                {user && <UserNav />}
              </div>
            </header>
            <div className="flex-1 flex flex-col items-center justify-center gap-8 mx-8">
              <div className="flex flex-col items-center">
                {mediaRecorder && isRecording && <LiveAudioVisualizer mediaRecorder={mediaRecorder} width={250} height={100} barWidth={4} gap={2} barColor="#fa466d" />}
                {/* {isRecording && <div className="text-4xl mb-5 mt-5">{formatTime(recordingTime)}</div>} */}
                {isRecording ? (
                  <div className="flex items-center gap-2">
                    <div className="flex flex-col items-center">
                      <Button size="iconBig" variant="outline" onClick={handleDeleteButton} className="text-white bg-transparent rounded-full">
                        <Trash size={24} color="white" />
                      </Button>
                      <span className="text-sm mt-1">Delete</span>
                    </div>
                    {!retrySave && (
                      <div className="flex flex-col items-center">
                        <Button size="iconPrimary" variant="primary" onClick={paused ? handleResume : handlePause} className="text-white bg-transparent rounded-full">
                          {paused ? <Play size={24} className="mic-text" /> : <Pause size={24} className="mic-text" />}
                        </Button>
                        {paused ? <span className="text-sm mt-1 mic-text">Resume</span> : <span className="text-sm mt-1 mic-text">Pause</span>}
                      </div>
                    )}
                    <div className="flex flex-col items-center">
                      {retrySave ? (
                        <>
                          <Button size="iconBig" variant="outline" onClick={handleSaveButton} className="text-white bg-transparent rounded-full">
                            <Save size={24} color="white" />
                          </Button>
                          <span className="text-sm mt-1">Retry save</span>
                        </>
                      ) : (
                        <>
                          <Button size="iconBig" variant="outline" onClick={handleSaveButton} className="text-white bg-transparent rounded-full">
                            <Save size={24} color="white" />
                          </Button>
                          <span className="text-sm mt-1">Save</span>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="relative flex justify-center items-center">
                    <>
                      <div className={`${disableRecording ? 'disabled' : ''} outer-circle-2`}></div>
                      <div className={`${disableRecording ? 'disabled' : ''} outer-circle-1`}></div>
                      <Button className={`${disableRecording ? 'disabled' : ''} mic-button rounded-full w-28 h-28 flex justify-center items-center`} onClick={start} disabled={disableRecording}>
                        <Mic className="h-12 w-12 text-white" />
                      </Button>
                    </>
                  </div>
                )}
              </div>
              {!isRecording && <span className={`text-md mt-1 mic-text ${disableRecording ? 'disabled' : ''}`}>{disableRecording ? 'You are not authorized to use the recorder, please contact the administrator.' : 'Start a new recording'}</span>}
              <div className="my-4 w-full max-w-md flex flex-col items-center justify-center">
                {isRecording && debugInfoVisible && (
                  <Collapsible open={isDebugInfoOpen} onOpenChange={() => setIsDebugInfoOpen((prev) => !prev)} className="w-full text-center">
                    <CollapsibleTrigger className="mb-6">
                      <span className="text-sm text-slate-400">Debug info</span>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <br />
                      Number of chunks: {chunks.length}
                      <br />
                      Time per chunk: {(currentTime.getTime() - startTime.getTime()) / 1000 / Number(numberOfChunks.toFixed(3))}
                      s
                      <br />
                      <br />
                      {session?.qr_code && (
                        <a href={session.qr_code} target="_blank" rel="noopener noreferrer">
                          {session.qr_code}
                        </a>
                      )}
                      <br />
                      {url && <a href={url}>Open file</a>}
                    </CollapsibleContent>
                  </Collapsible>
                )}
                <div className="w-full my-6">{audioUrl && <RecordedFiles audioUrl={audioUrl} audioBlob={file} />}</div>
                {chunks.length > 0 && <LocalUpload chunks={chunks} />}
              </div>
            </div>
          </div>

          <ContinueRecordingModal open={continueRecordingModalOpen} setOpen={setContinueRecordingModalOpen} startRecording={startRecording} stopRecording={stopRecording} />

          <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} handleDelete={handleDelete} />

          <DeleteConfirmationModal open={deleteConfirmationModalOpen} setOpen={setDeleteConfirmationModalOpen} />

          <SaveModal open={saveModalOpen} setOpen={setSaveModalOpen} handleSave={handleSave} language={language} participants={participants} setParticipants={setParticipants} setLanguage={setLanguage} meetingName={meetingName} setMeetingName={setMeetingName} />

          <SaveConfirmationModal open={saveConfirmationModalOpen} setOpen={setSaveConfirmationModalOpen} />

          <SettingsModal open={settingsModalOpen} setOpen={setSettingsModalOpen} devices={devices} selectedDeviceId={selectedDeviceId} />
          <RecordingsModal open={recordingsModalOpen} setOpen={setRecordingsModalOpen} />
          {toast.open && <Toast title={toast.title} description={toast.description} open={toast.open} type="error" onOpenChange={() => setToast({ ...toast, open: false })} />}
        </main>
      )}
    </>
  );
};

export default MainComponent;
